@import '../../styles/app';

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 60px;
  width: 60px;
  background: $warning;
  font-weight: 600;
  font-size: 18px;
}

.promoAlert {
  transition: 0.6s;
  transition-timing-function: ease;
  transform: translateX(-130vw);
}

.showAlert {
  transform: translateX(0);
}

.controBtn {
  padding: 1px 3px;
  font-size: 11px;
  color: $white;
}

.usersTableWrapper {
  overflow: hidden;
  @include media-breakpoint-down(lg) {
    overflow: auto;
  }
}

.usersListTableMobile {
  min-width: 1100px;
}

.tableAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 60px;
  width: 60px;
  background: #FDA700;
  font-weight: 600;
  font-size: 18px;
  img {
    height: 60px
  }
}
