/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300&display=swap');
@import '~bootstrap/scss/functions';
@import 'variables';
@import 'theme-variables';
@import '~bootstrap/scss/bootstrap';
@import '../fonts/flaticon/flaticon';
@import '~font-awesome/css/font-awesome';
@import '~font-awesome/scss/font-awesome';
// @import '~glyphicons-halflings/css/glyphicons-halflings';
// @import '~glyphicons-halflings/scss/glyphicons-halflings';
@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css';
@import '../../node_modules/line-awesome/dist/line-awesome/css/line-awesome.css';
@import '../../node_modules/animate.css/animate';
@import '../../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox';
@import '../../node_modules/messenger/build/css/messenger.css';
@import '../../node_modules/messenger/build/css/messenger-theme-air.css';
@import '../../node_modules/nvd3/build/nv.d3.css';
@import '../../node_modules/rickshaw/rickshaw.css';
@import '../../node_modules/react-table/react-table.css';
@import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
@import '../../node_modules/react-sortable-tree/style.css';
@import '../../node_modules/react-toastify/dist/ReactToastify.css';
@import '../../node_modules/react-tagsinput/react-tagsinput.css';

@import 'mixins';
@import 'base';
@import 'auth';
@import 'overrides';
@import 'general';
@import 'utils';

