// Meta

$enable-shadows:            true !default;

$fa-font-path: '~font-awesome/fonts/' !default;

/*
 * Typography
 * ======================================================================== */
$font-size-initial:       14px; // reset default browser value from 16px to 13px
$font-size-base:          1rem !default;
$font-size-lg:            1.25rem !default;
$font-size-sm:            0.875rem !default;
$font-size-larger:        1.1rem;
$font-size-mini:          0.9rem;
$font-size-index:         0.7rem;

$font-family-base: 'Poppins', sans-serif;

$headings-font-weight: 400;

// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #d6dee5 !default;
$gray-400: #c1ccd3 !default;
$gray-500: #a3aeb7 !default;
$gray-600: #798892 !default;
$gray-700: #495057 !default;
$gray-800: #3c484f !default;
$gray-900: #29323a !default;
$gray-default: #5A5D76 !default;
$black:    #000 !default;
$gray-light: $gray-100 !default;

$blue:         #6FB0F9 !default;
$blue-300: rgba($blue, 0.3) !default;
$indigo:       #7f3ddb !default;
$purple:       #10CFD0 !default;
$pink:         #e54e9a !default;
$red:          #FF5574 !default;
$orange:       #ff7d47 !default;
$yellow:       #EBB834 !default;
$green:        #68ca66 !default;
$teal:         #26CD5F !default;
$cyan:         #17a2b8 !default;
$dark-gray:    #D7DFE6 !default;
$gray-inverse: #30324C !default;
$subtle-white: #EAF0F6 !default;
$light-red: #FF5574 !default;


$green: #003C32 !default;
$green-300: rgba($green, 0.3) !default;

$theme-colors: (
  primary: $green,
  secondary: $gray-600,
  success: $teal,
  info: $purple,
  warning: $yellow,
  danger: $red,
  dark: $gray-800,
  inverse: $gray-inverse,
  gray: $gray-default,
  light: $gray-100,
  default: #9083F7,
  gray-default: $gray-default,
  primary-300: $green-300,
  primary-light: #dee4ee,
  success-light: #ecfaec,
  info-light: #f2fafa,
  warning-light: #fdf7e6,
  danger-light: #fff2ef,
);

$link-color: $green;

$text-color: #0C2236 !default;

$font-weight-bold: 700 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-normal: 400 !default;
$font-weight-thin: 300 !default;
$font-weight-base: $font-weight-thin !default;

$addition-bg: $gray-100 !default;

$body-bg: #F9FBFD !default;
$body-bg-light: lighten($body-bg, 3%);

//== Components
//

$grid-gutter-width: 40px;

$content-padding: 40px;
$content-padding-sm: 15px;

$nav-link-padding: 10px;

$border-radius:          0.3rem !default;
$border-radius-lg:       0.35rem !default;
$border-radius-sm:       0.25rem !default;

//== Sidebar
//

$sidebar-transition-time: 0.3s !default;

$sidebar-width-open: 230px;
$sidebar-width-closed: 50px;
$sidebar-transition-time: 0.3s !default;

$chat-sidebar-width-open: 250px;

$sidebar-border: 1px solid darken($body-bg, 2%);

$sidebar-item-border-color: transparent !default;
$sidebar-item-active-color: $yellow !default;
$sidebar-scrollbar-bg: var(--sidebar-item-active-color);

$breadcrumb-divider: '>';
$breadcrumb-padding-y: 0;
$breadcrumb-bg: transparent;

$widget-bg: #fff;
$widget-title-color: $text-color;
$widget-shadow-designated: -1px 0 8px rgba(0,0,0,.2);
$widget-border-radius: $border-radius-lg;

$input-btn-line-height: 1.5;

$input-btn-padding-y-sm: 0.4rem;
$input-btn-padding-x-sm: 0.8rem;

//== Theme Helper
//

$helper-width: 300px;

//== Navbar
//

$navbar-height:  60px !default;
$navbar-padding-y: 0 !default;
$navbar-padding-x: 0 !default;

// Custom navbar links
$navbar-link-color: $text-color !default;
$navbar-link-hover-color: $gray-800 !default;
$navbar-link-hover-bg: transparent !default;
$navbar-link-active-color: $gray-700 !default;
$navbar-link-active-bg: $gray-300 !default;
$navbar-link-disabled-color: $gray-400 !default;
$navbar-link-disabled-bg: transparent !default;

// Dropdowns
$dropdown-link-color: $text-color;

// Widgets
// -------------------------
$widget-padding-vertical: 15px !default;
$widget-padding-horizontal: 20px !default;
$widget-bg-color: $white;

//== Navs
//
$nav-tabs-active-link-hover-bg: $white !default;
$nav-tabs-active-link-hover-color: $gray-700 !default;

//== Forms
//
$input-focus-border-color: #4d90fe !default; //webkit focus color

//*  Popover  */
$popover-bg: #ebeff1 !default;
$popover-arrow-outer-color: $popover-bg !default;
$popover-arrow-color: $popover-bg !default;

// Badge
$badge-font-weight: 700;
$badge-font-size: 0.875em;
$badge-padding-y: 0.4em;
$badge-padding-x: 0.4em;

// Breadcrumbs
$breadcrumb-active-color: $gray-700;

// Progress bars
$progress-height: 0.65rem !default;
$progress-box-shadow: none;

// Alerts
$alert-transparent: 0.25;

// Buttons
$input-btn-padding-y:       0.375rem !default;
$input-btn-padding-x:       1rem !default;

$btn-default-bg: $addition-bg !default;
$btn-default-border: $addition-bg !default;

// Table
$table-border-width: 1px;

// Pagination
$pagination-color:                     $gray-700 !default;
$pagination-bg:                        #fff !default;
$pagination-border:                    transparent !default;

$pagination-hover-color:               $gray-700 !default;
$pagination-hover-bg:                  transparent !default;
$pagination-hover-border:              transparent !default;
$pagination-hover-border-color:        transparent !default;

$pagination-active-color:              $gray-700 !default;
$pagination-active-bg:                 transparent !default;
$pagination-active-border:             transparent !default;
$pagination-active-border-color:       transparent !default;

$pagination-disabled-color:            $gray-700 !default;
$pagination-disabled-bg:               #fff !default;
$pagination-disabled-border:           transparent !default;

// == Components
$padding-small-vertical:    0.25rem !default;
$padding-small-horizontal:  0.75rem !default;

// Screens
// -------------------------

$screen-lg-height: 670px !default;


//== Chat Sidebar
//
$chat-sidebar-width-open: 250px;

$chat-component-padding: 21px;
$chat-section-width: 330px;

$blockquote-small-color:      $gray-600 !default;
$hr-border-color:             rgba($black, .1) !default;
$table-cell-padding:          .75rem !default;
$emphasized-link-hover-darken-percentage: 15% !default;
$badge-focus-width:          .2rem !default;
