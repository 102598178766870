@import '../../styles/app';

.root {
  width: $sidebar-width-open;
  left: 0;
  top: 0;
  bottom: 0;
  border-right: $sidebar-border;
  background-color: var(--sidebar-bg-color);
  color: var(--sidebar-color);
  overflow-y: auto;
  position: absolute;
  @include scroll-bar($sidebar-scrollbar-bg);
}

.logoStyle {
  color: var(--sidebar-icon-bg);
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16.5px;
  text-shadow: 4px 4px 15px rgba(92, 176, 255, .3);

}

.sidebarClose .logoPart {
  opacity: 0;
}

.logoPart {
  opacity: 1;
  transition: opacity $sidebar-transition-time ease-in-out;
}

.sidebarWrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: $sidebar-width-open;
  transition: width $sidebar-transition-time ease-in-out;
}

.sidebarClose.sidebarWrapper {
  width: $sidebar-width-closed; 
  @include media-breakpoint-down(sm) {
    width: 0;
  }
}


.logo {
  padding: 10px;
  font-size: 18px;
  width: 100%;
  font-weight: $font-weight-thin;
  text-align: center;
  transition: width $sidebar-transition-time ease-in-out;

  a {
    color: var(--logo-color);
    text-decoration: none;
    white-space: nowrap;
    display: block;
    img {
      height: calc($navbar-height - 10px);
    }
  }
  
}

.sidebarClose .logo {
  width: 50px;
}

.staticSidebar .logo {
  width: 100%;
}

.nav {
  padding: 30px 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.navTitle {
  margin: 35px 0 5px 11px;
  font-size: $font-size-mini;
  transition: opacity $sidebar-transition-time ease-in-out;
  color: var(--sidebar-section);
  opacity: .6;
  font-weight: bold;
}

.sidebarClose .navTitle {
  opacity: 0;
}

.staticSidebar .navTitle {
  opacity: 1;
  transition: none;
}

.actionLink {
  color: #aaa;
  float: right;
  margin-right: 15px;
  margin-top: -1px;
}

.labelName {
  opacity: 1;
  padding-left: 15px;
  transition: opacity $sidebar-transition-time ease-in-out;
}

.sidebarClose .labelName {
  opacity: 0;
}

.staticSidebar .labelName {
  transition: none;
  opacity: 1;
}

.glyphiconSm {
  font-size: 9px;
}

.sidebarLabels {
  list-style-type: none;
  padding: 11px;
  padding-right: 15px;

  & > li {
    margin: 25px 0
  }

  li > a {
    font-size: $font-size-mini;
    color: var(--sidebar-color);
    text-decoration: none;

    > i {
      font-size: 11px;
      vertical-align: 1px;
      transition: margin-left $sidebar-transition-time ease-in-out;
    }
  }
}

.sidebarClose {
  .sidebarLabels > li > a > i {
    margin-left: 8px;
    transition: margin-left $sidebar-transition-time ease-in-out;
  }
}

.staticSidebar {
  .sidebarLabels > li > a > i {
    transition: none;
    margin-left: 0;
  }
}

.sidebarAlerts {
  margin-bottom: $spacer * 2;
  transition: opacity $sidebar-transition-time ease-in-out;
  opacity: 1;
  .btn-close {
    padding: 0;
  }
}

.sidebarClose .sidebarAlerts {
  opacity: 0;
}

.staticSidebar .sidebarAlerts {
  opacity: 1;
  transition: none;
}

.sidebarAlert {
  background: transparent;
  margin-bottom: 0;
  padding: 0.5rem 11px;
  padding-right: 15px;
  & > button > span {
    font-size: 32px;
    color: var(--sidebar-icon-bg);
  }
}

.sidebarProgress {
  background-color: var(--sidebar-progress-bg-color);
}

.groupTitle {
  margin-bottom: 15px;
}

.labelRecent {
  color: var(--sidebar-core);
}

.labelStarred {
  color: var(--sidebar-ui);
}

.labelBackground {
  color: var(--sidebar-form);
}

:global {
  .sidebar-bottom-aler-danger {
    & > .progress-bar {
      background-color: var(--sidebar-sales);
    }
  }

  .sidebar-bottom-aler-primary {
    & > .progress-bar {
      background-color: var(--sideba-responsibility);
    }
  }
}