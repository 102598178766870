@import '../../../styles/app';

.headerLink {
  overflow-x: hidden;
  
  @media (min-width: map_get($grid-breakpoints, lg)) and (min-height: $screen-lg-height), (max-width: map_get($grid-breakpoints, md) - 1px) {
    font-size: 13px;
  }

  a {
    display: block;
    color: var(--sidebar-color);
    transition: all .35s ease;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  &:last-child > a {
    border-bottom: 1px solid $sidebar-item-border-color;
  }

  > a {
    position: relative;
    align-items: center;
    padding-left: 50px;
    line-height: 35px;
    border-top: 1px solid $sidebar-item-border-color;

    &:hover {
      background-color: var(--sidebar-item-hover-bg-color);
    }

    > i {
      margin-right: 7px;
    }

    @media (min-width: map_get($grid-breakpoints, lg)) and (min-height: $screen-lg-height), (max-width: map_get($grid-breakpoints, md) - 1px) {
      line-height: 55px;
    }
  }

  .icon {
    font-size: $font-size-larger;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .35s ease;
    color: var(--sidebar-color);
    background-color: transparent;
    position: absolute;
    top: 3px;
    left: 11px;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 0;
    opacity: .7;

    @media (min-width: map_get($grid-breakpoints, lg)) and (min-height: $screen-lg-height), (max-width: map_get($grid-breakpoints, md) - 1px) {
      top: 12px;
    }
  }

  .badge {
    float: right;
    line-height: 8px;
    margin-top: 9px;
    margin-right: 15px;
    padding: 0.5em 0.5em;
    font-size: 0.875em;
    //background: var(--sidebar-icon-bg);
    color: var(--sidebar-badge-color);
    @media (min-width: map_get($grid-breakpoints, lg)) and (min-height: $screen-lg-height), (max-width: map_get($grid-breakpoints, md) - 1px) {
      margin-top: 16px;
    }
  }

  .headerNode {
    color: var(--sidebar-hightlight-two)
  }

  .headerUpdate {
    color: var(--sidebar-hightlight)
  }
  
  a.headerLinkActive {
    color: var(--sidebar-item-active-color);
    font-weight: $font-weight-bold;
    white-space: nowrap;
    
    &:hover {
      color: var(--sidebar-icon-bg);
    }

    .icon {
      border-radius: 50%;
      background-color: var(--sidebar-icon-bg);
      opacity: 1;
      display: flex;
      justify-content: center;
      transform: rotate(360deg);
      align-items: center;
      color: var(--sidebar-bg-color);
      i {
        color: var(--sidebar-bg-color);
      }
    }
  }
}

.headerLabel {
  font-weight: 600;
  color: var(--sidebar-item-active-color);
}

.collapsed .caret {
  transform: rotate(-90deg);
}

.caret {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 19px;
  margin-right: 15px;
  color: var(--sidebar-color);
  @include transition(transform 0.3s ease-in-out);
}

.panel {
  border: none;
  box-shadow: none;
  margin: 0;
  border-radius: 0;

  a.headerLinkActive {
    font-weight: $font-weight-bold;
    color: var(--sidebar-color);

    &:hover {
      color: var(--sidebar-color);
    }
  }

  ul {
    li {
      padding: $spacer/2 $spacer;
      list-style: none;
    }

    a {
      padding: 10px 20px 10px 26px;
      font-size: $font-size-mini;

      &.headerLinkActive,
      &:hover {
        background-color: var(--sidebar-item-hover-bg-color);
      }
    }
  }
}

